import { render, staticRenderFns } from "./_.vue?vue&type=template&id=0f2368b2"
import script from "./_.vue?vue&type=script&lang=js"
export * from "./_.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzBreadcrumbs: require('/opt/razlet.ru.v2/node_modules/@razlet/ui/src/components/breadcrumbs/index.vue').default,RzStatusHeader: require('/opt/razlet.ru.v2/node_modules/@razlet/ui/src/components/status-header/index.vue').default,Sulu: require('/opt/razlet.ru.v2/node_modules/@razlet/sulu-sdk/src/components/index.vue').default})
